import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["masterCheckbox", "checkbox"]

  toggle = (e) => {
    if (e.target != this.masterCheckboxTarget) {
      this.masterCheckboxTarget.checked = !this.masterCheckboxTarget.checked;
    }

    const checked = this.masterCheckboxTarget.checked
    this.checkboxTargets.forEach((checkbox) => {
      if (checkbox.disabled) return;
      checkbox.checked = checked;
    });
  }
}
